<template>
  <Suspense>
    <slot />

    <template v-if="!withoutLoading" #fallback>
      <slot name="loading">
        <Loading :placement="internalLoading ? 'internal' : 'external'" />
      </slot>
    </template>
  </Suspense>
</template>

<script setup>
import Loading from '@/Components/common/Loading.vue'

defineProps({
  withoutLoading: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  internalLoading: {
    type: Boolean,
    required: false,
    default: () => false,
  },
})
</script>
