<template>
  <header
    class="z-[50] w-full px-4 tracking-[0.2px] subpixel-antialiased 2xl:px-[8%]"
  >
    <div class="flex h-[60px] md:h-[78px] items-center">
      <!-- Mobile Dropdown -->
      <Menu as="div" class="relative min-w-[30px] md:min-w-0 shrink-0">
        <MenuButton
          class="flex md:hidden h-[35px] items-center justify-center rounded-[5px]"
        >
          <FontAwesomeIcon
            :icon="['fal', 'bars']"
            class="text-2xl text-white"
          />
        </MenuButton>

        <MenuItems
          as="div"
          class="fixed top-[78px] left-0 w-full bg-[#3B1C54] border-t border-[#5B3397] px-7 pt-8 pb-10 text-white shadow-[0px_4px_4px_0px_rgba(0,0,0,0.1)] text-center"
        >
          <MenuItem
            as="a"
            class="mb-4 py-[6px] text-sm font-medium block"
            href="https://www.airlst.com/de/kontakt"
            target="_blank"
          >
            {{ $t('settings.contact_us') }}
          </MenuItem>

          <MenuItem
            as="div"
            class="mb-6 py-[6px] text-sm font-medium border border-white rounded-[5px]"
            @click="router.get(useRoute('login'))"
          >
            {{ $t('auth.sign_in') }}
          </MenuItem>

          <MenuItem
            as="div"
            class="py-[6px] text-sm font-medium border border-[#9C63FF] bg-[#9C63FF] rounded-[5px]"
            @click="router.get(useRoute('register'))"
          >
            {{ $t('auth.sign_up') }}
          </MenuItem>
        </MenuItems>
      </Menu>

      <div class="flex-1 flex justify-center md:justify-start">
        <div>
          <img
            :src="Vapor.asset('images/airlst-logo-white.png')"
            alt="airlst-logo-white"
            class="md:mb-[4px] w-[135px] cursor-pointer"
            data-test="logo-img"
            @click="router.get(useRoute('dashboard'))"
          />
        </div>

        <div
          class="flex-1 hidden md:flex items-center justify-end gap-x-2 text-white"
        >
          <a
            as="a"
            class="py-[6px] px-6 text-sm font-medium block"
            href="https://www.airlst.com/de/kontakt"
            target="_blank"
          >
            {{ $t('settings.contact_us') }}
          </a>

          <button
            class="whitespace-nowrap py-[6px] px-6 text-sm font-medium border border-white rounded-[5px] text-center"
            @click="router.get(useRoute('login'))"
          >
            {{ $t('auth.sign_in') }}
          </button>

          <button
            class="whitespace-nowrap py-[6px] px-6 text-sm font-medium border border-[#9C63FF] bg-[#9C63FF] rounded-[5px] text-center"
            @click="router.get(useRoute('register'))"
          >
            {{ $t('auth.sign_up') }}
          </button>
        </div>
      </div>

      <div class="flex items-center justify-end shrink-0 md:ml-6">
        <SelectLanguage dropdown-class="top-[48px] rounded-none" />
      </div>
    </div>
  </header>
</template>

<script setup>
import SelectLanguage from '@/Components/Language/SelectLanguage.vue'
import { useRoute } from '@/composables/route'
import { router } from '@inertiajs/vue3'
import Vapor from 'laravel-vapor'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
</script>
