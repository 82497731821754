<template>
  <div class="relative mt-20 md:mt-0">
    <div
      class="absolute right-0 bottom-[60px] flex w-full items-center px-[20px] 2xl:px-[8%]"
    >
      <div
        data-test="made-with-text"
        class="block md:hidden relative text-[12px]"
      >
        {{ $t('auth.made_with') }}
        <FontAwesomeIcon
          :icon="['fas', 'heart']"
          class="text-sm text-[#c547d3]"
        />
        {{ $t('auth.on_earth') }}
      </div>
    </div>

    <div
      class="relative flex h-[55px] w-full items-center justify-between bg-transparent px-[20px] text-[9px] md:text-[13px] tracking-[0.2px] text-[#3d4146] subpixel-antialiased shadow-[0_0_4px_rgb(15,34,58,0.12)] 2xl:px-[8%] bg-white"
    >
      <div
        data-test="year-airlst"
        class="flex flex-wrap items-center gap-y-2 gap-x-[2px] md:gap-x-1 relative whitespace-nowrap"
      >
        {{ new Date().getFullYear() }} ©
        <a
          target="_blank"
          rel="noopener"
          href="http://airlst.com/"
          class="hover:text-[#333]"
          >AirLST GmbH</a
        >
        |
        <Link
          class="hover:text-[#dbdbdb]"
          :href="useRoute('termsOfUse', { locale: useCurrentLocaleCode() })"
        >
          {{ $t('auth.terms_of_use') }}
        </Link>
        -
        <Link
          class="hover:text-[#dbdbdb]"
          :href="useRoute('privacyPolicy', { locale: useCurrentLocaleCode() })"
        >
          {{ $t('auth.privacy_policy') }}
        </Link>
        -
        <Link
          class="hover:text-[#dbdbdb]"
          :href="useRoute('imprint', { locale: useCurrentLocaleCode() })"
        >
          {{ $t('auth.legal_disclosure') }}
        </Link>
        |
        <a
          class="hover:text-[#dbdbdb]"
          href="https://portal.productboard.com/fybc14bmdpqvjzkkagavyxeu"
          target="_blank"
        >
          {{ $t('auth.roadmap') }}
        </a>
        -
        <a
          class="hover:text-[#dbdbdb]"
          href="https://airlst-suite.statuspage.io/"
          target="_blank"
        >
          {{ $t('auth.airlst_status') }}
        </a>
      </div>

      <div data-test="made-with-text" class="hidden md:block relative">
        {{ $t('auth.made_with') }}
        <FontAwesomeIcon
          :icon="['fas', 'heart']"
          class="text-sm text-[#c547d3]"
        />
        {{ $t('auth.on_earth') }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { Link } from '@inertiajs/vue3'
import { useRoute } from '@/composables/route'
import { useCurrentLocaleCode } from '@/composables/locale.js'
</script>
