<template>
  <div
    class="py-3 bg-[#9C63FF] flex flex-col md:flex-row gap-y-2 gap-x-4 items-center justify-center text-white font-bold"
    data-test="free-plan-banner-wrapper"
  >
    <p
      class="text-sm"
      data-test="free-plan-banner-text"
      v-text="$t('subscription.free_plan.banner.text')"
    />
    <button
      v-if="usePermission('company:update')"
      class="bg-white py-[5px] px-6 text-[#36194E] rounded-2xl text-xs"
      data-test="free-plan-banner-button"
      @click="router.get(useRoute('companies.subscriptions.show'))"
      v-text="$t('subscription.free_plan.banner.button.text')"
    />
  </div>
</template>

<script setup>
import { usePermission } from '@/composables/permission.js'
import { router } from '@inertiajs/vue3'
import { useRoute } from '@/composables/route'
</script>
