<template>
  <Locales v-slot="{ list, current, change }">
    <Menu
      v-slot="{ open }"
      as="div"
      class="relative ml-2 md:ml-4 inline-block text-left"
    >
      <div>
        <MenuButton
          class="w-5 rounded-[5px] py-[5px] focus:outline-none text-[#9c63ff]"
          :class="{
            'text-white': open,
          }"
        >
          <img
            :src="Vapor.asset(`images/flags/${current.code}.gif`)"
            alt="flag-img"
            class="w-5 h-5 rounded-full border-[2px] border-[#9c63ff] mx-auto"
          />
        </MenuButton>
      </div>

      <Transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          :class="[
            'absolute right-0 z-10 mt-2 min-w-[200px] origin-top-right overflow-hidden rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
            dropdownClass,
          ]"
        >
          <h3
            class="text-[#3f1e59] opacity-[0.4] text-[11px] font-bold uppercase tracking-[1px] py-2 px-4 mb-1"
            v-text="$t('common.language')"
          />
          <MenuItem
            v-for="{ code, label } in list"
            :key="code"
            v-slot="{ active }"
          >
            <div
              :class="[
                active || code === current.code
                  ? 'text-primary'
                  : 'text-gray-700',
                'block cursor-pointer px-4 py-2 text-sm font-medium',
              ]"
              @click="change(code)"
              v-text="label"
            />
          </MenuItem>
        </MenuItems>
      </Transition>
    </Menu>
  </Locales>
</template>

<script setup>
import Locales from '@/Components/Headless/Locales'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import Vapor from 'laravel-vapor'

defineProps({
  dropdownClass: {
    type: [String, Array, Object],
    default: '',
  },
})
</script>
