<template>
  <div
    class="z-50 flex h-full w-full items-center justify-center"
    :class="{
      'fixed top-0 left-0 bg-[rgba(0,0,0,0.3)]': placement === 'external',
    }"
  >
    <div
      class="rounded-[10px] px-6 pt-5 pb-3 text-[13px] font-medium shadow-[0_2px_3px_rgb(52,58,64,0.06)]"
      :class="{
        'bg-white': placement === 'external',
        'text-white': placement === 'internal',
      }"
    >
      <FontAwesomeIcon
        :icon="['fal', 'spinner']"
        class="mx-auto mb-3 block text-3xl"
        :class="{
          'text-primary': placement === 'external',
          'text-white': placement === 'internal',
        }"
        spin
      />
      {{ $t('common.loading') }}
    </div>
  </div>
</template>

<script setup>
defineProps({
  placement: {
    type: String,
    default: 'external',
  },
})
</script>
