<template>
  <div :class="[menuClass]">
    <Notifications
      v-slot="{
        list,
        unread,
        markAsRead,
        markAsUnread,
        delete: deleteNotification,
      }"
    >
      <Menu
        v-slot="{ close }"
        as="div"
        class="antialiased relative mr-[14px] inline-block text-left"
      >
        <MenuButton
          id="notif-menu-btn"
          :class="[
            'relative w-7 rounded-[5px] py-[5px] focus:outline-none',
            menuBtnClass,
          ]"
        >
          <FontAwesomeIcon
            :icon="['fad', 'bell']"
            class="text-[24px] text-[#9c63ff]"
          />

          <div
            v-if="unread.has"
            class="absolute top-[-1px] right-[-3px] flex h-[15px] w-[15px] items-center justify-center rounded-full bg-[red] text-[10px] font-semibold leading-none text-white"
            v-text="unread.count"
          />
        </MenuButton>

        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            :class="[
              'absolute -right-6 z-10 w-96 origin-top-right overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
              dropdownClass,
            ]"
          >
            <div class="flex items-center justify-between p-5">
              <h3 class="font-semibold text-xl">
                {{ $t('notification_center.title') }}
              </h3>
              <button @click="close">
                <FontAwesomeIcon
                  :icon="['fal', 'xmark']"
                  class="text-black text-xl"
                />
              </button>
            </div>

            <p
              v-if="!list.length"
              class="py-3 px-2 text-center text-sm italic text-gray-900"
              v-text="$t('common.no_notifications')"
            />
            <div class="max-h-[380px] overflow-y-auto">
              <MenuItem
                v-for="{
                  id,
                  title,
                  description,
                  read,
                  link,
                  timestamp,
                } in list"
                :key="id"
                disabled
              >
                <div
                  :class="[
                    'relative block border-b px-5 py-4 text-sm last:border-b-0',
                    !read
                      ? 'border-[rgba(0,0,0,0.1)]'
                      : 'bg-[#F9F9F9] border-[rgba(0,0,0,0.2)]',
                  ]"
                >
                  <div
                    v-if="!read"
                    class="absolute top-0 left-0 h-full w-[4px] bg-primary"
                  ></div>
                  <div class="flex gap-2">
                    <div class="flex-1 space-y-[9px]">
                      <p
                        class="text-[0.9rem] leading-[1]"
                        :class="!read ? 'text-black font-bold' : 'font-medium'"
                        v-text="title"
                      />
                      <p
                        v-if="description"
                        class="text-[0.8rem]"
                        v-text="description"
                      />
                      <div v-if="link">
                        <a
                          class="text-[#9c63ff] font-bold text-[0.8rem] inline-block"
                          :target="link.target ?? '_blank'"
                          :href="link.href"
                        >
                          {{ link.text }}
                        </a>
                      </div>
                    </div>

                    <div
                      v-if="timestamp"
                      class="space-y-1 text-[0.75rem] text-right leading-[1]"
                      :class="
                        !read ? 'text-black font-semibold' : 'font-medium'
                      "
                    >
                      <p v-text="formatDate(timestamp)"></p>
                      <p v-text="formatTime(timestamp)"></p>
                    </div>
                  </div>
                  <div
                    class="flex justify-end gap-x-4 text-xs font-medium"
                    :class="link ? 'mt-4' : 'mt-6'"
                  >
                    <button
                      type="button"
                      class="focus:outline-none hover:opacity-70 transition duration-[0.3s] ease-in-out"
                      @click="
                        read ? markAsUnread.one({ id }) : markAsRead.one({ id })
                      "
                    >
                      <FontAwesomeIcon
                        :icon="
                          !read ? ['far', 'envelope-open'] : ['fal', 'envelope']
                        "
                        class="text-lg text-black"
                        :class="read && 'opacity-50'"
                      />
                    </button>

                    <button
                      type="button"
                      class="focus:outline-none hover:opacity-70 transition duration-[0.3s] ease-in-out"
                      @click="deleteNotification.one({ id })"
                    >
                      <FontAwesomeIcon
                        :icon="['fal', 'trash-alt']"
                        class="text-lg text-[#9C63FF]"
                      />
                    </button>
                  </div>
                </div>
              </MenuItem>
            </div>

            <div
              v-if="list.length"
              class="flex items-center justify-end pt-5 pb-4 px-5 gap-x-6"
            >
              <button
                type="button"
                class="text-[#9C63FF] flex items-center font-medium gap-x-2 text-xs hover:opacity-80 transition duration-[0.3s] ease-in-out"
                @click="() => deleteNotification.all()"
              >
                <FontAwesomeIcon :icon="['fal', 'trash-alt']" class="text-lg" />

                {{ $t('notification_center.delete_all') }}
              </button>

              <button
                class="text-[#9C63FF] flex items-center font-medium gap-x-2 text-xs hover:opacity-80 transition duration-[0.3s] ease-in-out"
                @click="markAsRead.all()"
              >
                <FontAwesomeIcon
                  :icon="['fal', 'check-double']"
                  class="text-lg"
                />

                {{ $t('notification_center.mark_all_as_read') }}
              </button>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </Notifications>
  </div>
</template>

<script setup>
import Notifications from '@/Components/Headless/Notifications'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { formatDate, formatTime } from '@/composables/date'

defineProps({
  menuClass: {
    type: String,
    default: '',
  },
  menuBtnClass: {
    type: String,
    default: '',
  },
  dropdownClass: {
    type: String,
    default: '',
  },
})
</script>
